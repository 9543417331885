<template>
  <div class="account-form">
    <div class="account-card">
      <div class="account-card__label">{{ $t("wx") }}</div>
      <div class="account-card__content">
        <div>{{ account.isWechatBind ? $t("binding") : $t("no_binding") }}</div>
        <div class="text-black-light mt-10 text-min">
          {{ $t("third_binding_login") }}
        </div>
      </div>
      <div class="account-card__action">
        <el-link v-if="!account.isWechatBind" @click="openWindow('wx')">{{
          $t("to_binding")
        }}</el-link>
      </div>
    </div>
    <div class="account-card">
      <div class="account-card__label">{{ $t("alipay") }}</div>
      <div class="account-card__content">
        <div>{{ account.isAlipayBind ? $t("binding") : $t("no_binding") }}</div>
        <div class="text-black-light mt-10 text-min">
          {{ $t("third_binding_login") }}
        </div>
      </div>
      <div class="account-card__action">
        <el-link v-if="!account.isAlipayBind" @click="openWindow('zfb')">{{
          $t("to_binding")
        }}</el-link>
      </div>
    </div>
    <div class="account-card">
      <div class="account-card__label">QQ</div>
      <div class="account-card__content">
        <div>{{ account.isQQBind ? $t("binding") : $t("no_binding") }}</div>
        <div class="text-black-light mt-10 text-min">
          {{ $t("third_binding_login") }}
        </div>
      </div>
      <div class="account-card__action">
        <el-link v-if="!account.isQQBind" @click="openWindow('qq')">{{
          $t("to_binding")
        }}</el-link>
      </div>
    </div>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  aliLoginHref,
  qqLoginHref,
  wxLoginHref
} from "../../../../../common/constants";

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions(["setDialogVisible"]),
    openWindow(type) {
      if (type === "wx") {
        window.open(wxLoginHref);
      } else if (type === "zfb") {
        window.open(aliLoginHref);
      } else if (type === "qq") {
        window.open(qqLoginHref);
      }
    }
  },
  computed: {
    ...mapGetters(["account", "dialogVisible"])
  },
  components: {}
};
</script>
