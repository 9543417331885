<template>
  <div class="person-form">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
    >
      <el-form-item :label="$t('name')" prop="name">
        <el-input
          style="width: 220px"
          v-model="ruleForm.name"
          :disabled="!!userInfo.name"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('gender')" prop="gender">
        <el-radio-group
          v-model="ruleForm.gender"
          @change="changeGender"
          :disabled="!!userInfo.gender"
        >
          <el-radio :label="1">{{ $t("man") }}</el-radio>
          <el-radio :label="2">{{ $t("woman") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('birthday')" prop="birthday">
        <el-date-picker
          :disabled="!!userInfo.birthday"
          type="date"
          :placeholder="$t('choose_data')"
          v-model="ruleForm.birthday"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('marital_status')" prop="maritalStatusID">
        <el-radio-group v-model="ruleForm.maritalStatusID">
          <el-radio v-for="i in maritalStatuses" :label="i.id" :key="i.id">{{
            i.nameCn
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('place_live')" required>
        <el-col :span="5"
          ><!--style="margin-left:-10px;"-->
          <el-form-item prop="provinceID">
            <el-select
              v-model="ruleForm.provinceID"
              :placeholder="$t('province_place_live')"
              @change="provinceChange"
            >
              <el-option
                v-for="i in provinces"
                :label="i.nameCn"
                :value="i.id"
                :key="i.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="cityID">
            <el-select
              v-model="ruleForm.cityID"
              :placeholder="$t('city_place_live')"
            >
              <el-option
                v-for="i in cities"
                :label="i.nameCn"
                :value="i.id"
                :key="i.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item prop="educationID" :label="$t('education')">
        <el-select
          v-model="ruleForm.educationID"
          :placeholder="$t('choose_education')"
        >
          <el-option
            v-for="i in levelsOfEducations"
            :label="i.nameCn"
            :value="i.id"
            :key="i.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="occupationStatusID" :label="$t('occupation_status')">
        <el-select
          v-model="ruleForm.occupationStatusID"
          :placeholder="$t('choose_occupation_status')"
        >
          <el-option
            v-for="i in occupationStatus"
            :label="i.nameCn"
            :value="i.id"
            :key="i.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="positionID" :label="$t('position')">
        <el-select
          v-model="ruleForm.positionID"
          :placeholder="$t('choose_position')"
        >
          <el-option
            v-for="i in positions"
            :label="i.nameCn"
            :value="i.id"
            :key="i.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="personalIncomeID" :label="$t('personal_income')">
        <el-select
          v-model="ruleForm.personalIncomeID"
          :placeholder="$t('choose_personal_income')"
        >
          <el-option
            v-for="i in personalIncomes"
            :label="i.nameCn"
            :value="i.id"
            :key="i.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="householdIncomeID" :label="$t('household_income')">
        <el-select
          v-model="ruleForm.householdIncomeID"
          :placeholder="$t('choose_household_income')"
        >
          <el-option
            v-for="i in personalIncomes"
            :label="i.nameCn"
            :value="i.id"
            :key="i.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="brandIDOfCar" :label="$t('private_car')">
        <el-select
          v-model="ruleForm.brandIDOfCar"
          :placeholder="$t('brand_car')"
          @change="carMakeChange"
        >
          <el-option
            v-for="i in brandIDOfCars"
            :label="i.nameCn"
            :value="i.id"
            :key="i.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        prop="modelIDOfCar"
        :label="$t('model_of_car')"
        v-if="ruleForm.brandIDOfCar && ruleForm.brandIDOfCar !== 1"
      >
        <el-select
          v-model="ruleForm.modelIDOfCar"
          :placeholder="$t('choose_model_of_car')"
        >
          <el-option
            v-for="i in modelIDOfCars"
            :label="i.nameCn"
            :value="i.id"
            :key="i.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        prop="purchasedYearOfCar"
        :label="$t('purchase_year_of_car')"
        v-if="ruleForm.brandIDOfCar && ruleForm.brandIDOfCar !== 1"
      >
        <el-date-picker
          v-model="ruleForm.purchasedYearOfCar"
          type="year"
          :placeholder="$t('choose_purchase_year_of_car')"
          value-format="yyyy"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="parnetsOfChilden" :label="$t('parnets_of_childen')">
        <el-select
          v-model="ruleForm.parnetsOfChilden"
          :placeholder="$t('parnets_of_childen')"
        >
          <el-option :label="$t('you')" :value="true"></el-option>
          <el-option :label="$t('meiyou')" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.parnetsOfChilden"
        prop="birthYearOfYoungChild"
        :label="$t('birthyear_of_young_child')"
      >
        <el-date-picker
          v-model="ruleForm.birthYearOfYoungChild"
          :placeholder="$t('year')"
          type="year"
          value-format="yyyy"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="personalDisease" label="个人患有以下疾病">
        <el-select
          v-model="ruleForm.personalDisease"
          placeholder="是否患病"
          multiple
          @change="changePersonalDisease"
        >
          <el-option
            v-for="i in diseasesList1"
            :label="i.nameCn"
            :value="i.id"
            :key="i.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="familyDisease" label="家人患有以下疾病">
        <el-select
          v-model="ruleForm.familyDisease"
          placeholder="是否患病"
          multiple
          @change="changeFamilyDisease"
        >
          <el-option
            v-for="i in diseasesList2"
            :label="i.nameCn"
            :value="i.id"
            :key="i.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">{{
          $t("save")
        }}</el-button>
      </el-form-item>
      <el-form-item style="margin-top: -10px"
        ><span class="text-orange text-min">{{
          $t("save_tip")
        }}</span></el-form-item
      >
    </el-form>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    var familyIncomeValidate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("family_income_required")));
      }
      if (value < this.ruleForm.personalIncomeID) {
        return callback(new Error(this.$t("family_income_tip")));
      }
      callback();
    };
    var nameValidate = (rule, value, callback) => {
      if (!/^[\u4e00-\u9fa5]{1,4}$/.test(value)) {
        return callback(new Error(this.$t("name_tip1")));
      }
      callback();
    };
    return {
      activeIndex: "account",
      basicData: {},
      provinces: [],
      maritalStatuses: [],
      levelsOfEducations: [],
      cities: [],
      occupationStatus: [],
      positions: [],
      personalIncomes: [],
      householdIncomes: [],
      brandIDOfCars: [],
      modelIDOfCars: [],
      purchasedYearOfCars: [],
      parnetsOfChildens: [],
      birthYearOfYoungChilds: [],
      diseasesList1: [],
      diseasesList2: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      ruleForm: {
        name: "",
        gender: "1", //性别（1-男，2-女）   |
        birthday: "", // | DateTime   |          | 出生日期 |
        provinceID: "", // | Int        |          | 省份     |
        cityID: "", //| Int       |          |  城市     |
        maritalStatusID: "", //| Int       |          |  婚姻状况  |
        educationID: "", //| Int       |          |  教育程度 |
        occupationStatusID: "", //| Int       |          | 工作状态 |
        positionID: "", //| Int       |          | 职务（职位） |
        personalIncomeID: "", //| Int       |          | 个人月收入 |
        householdIncomeID: "", //| Int       |          | 家庭月收入 |
        brandIDOfCar: "", //| Int       |          | 汽车品牌 |
        modelIDOfCar: "", //| Int       |          | 汽车型号 |
        purchasedYearOfCar: null, //| Int       |          | 汽车购买年份 |
        parnetsOfChilden: "", //| Boolean  |          | 是否小孩父母 |
        birthYearOfYoungChild: null, //| Int     |          | 小孩出生年份 |
        personalDisease: null, //个人患有以下疾病
        familyDisease: null, //家人患有以下疾病
      },

      rules: {
        name: [{ required: true, validator: nameValidate, trigger: "blur" }],
        gender: [{ required: true, message: this.$t("choose_gender") }], //性别（1-男，2-女）   |
        birthday: [
          {
            required: true,
            message: this.$t("choose_data_tip"),
            trigger: "change",
          },
        ],
        provinceID: [
          {
            required: true,
            message: this.$t("province_place_live"),
            trigger: "change",
          },
        ],
        cityID: [
          {
            required: true,
            message: this.$t("city_place_live"),
            trigger: "change",
          },
        ],
        maritalStatusID: [
          {
            required: true,
            trigger: "change",
            message: this.$t("choose_marital_status"),
          },
        ],
        educationID: [{ required: true, message: this.$t("choose_education") }], //| Int       |          |  教育程度 |
        occupationStatusID: [
          { required: true, message: this.$t("choose_occupation_status") },
        ], //| Int       |          | 工作状态 |
        positionID: [{ required: true, message: this.$t("choose_position") }], //| Int       |          | 职务（职位） |
        personalIncomeID: [
          { required: true, message: this.$t("choose_personal_income") },
        ], //| Int       |          | 个人月收入 |
        householdIncomeID: [
          {
            required: true,
            validator: familyIncomeValidate,
            trigger: "change",
          },
        ], //| Int       |          | 家庭月收入 |
        brandIDOfCar: [{ required: true, message: this.$t("brand_car") }], //| Int       |          | 汽车品牌 |
        modelIDOfCar: [
          { required: true, message: this.$t("choose_model_of_car") },
        ], //| Int       |          | 汽车型号 |
        purchasedYearOfCar: [
          {
            required: true,
            message: this.$t("choose_purchase_year_of_car"),
            trigger: "change",
          },
        ], //| Int       |          | 汽车购买年份 |
        parnetsOfChilden: [
          { required: true, message: this.$t("choose_parnets_of_childen") },
        ], //| Boolean  |          | 是否小孩父母 |
        birthYearOfYoungChild: [
          {
            required: true,
            message: this.$t("choose_birthyear_of_young_child"),
            trigger: "change",
          },
        ], //| Int     |          | 小孩出生年份 |
        personalDisease: [
          {
            required: true,
            message: "请选择是否患病",
            trigger: "change",
          },
        ],
        familyDisease: [
          {
            required: true,
            message: "请选择是否患病",
            trigger: "change",
          },
        ],
      },
    };
  },
  async created() {
    this.$http.get("/api/v1/Provinces").then((res) => {
      this.provinces = res.data;
    });
    this.$http.get("/api/v1/LevelsOfEducation").then((res) => {
      this.levelsOfEducations = res.data;
    });
    this.$http.get("/api/v1/OccupationStatus").then((res) => {
      this.occupationStatus = res.data;
    });
    this.$http.get("/api/v1/Positions").then((res) => {
      this.positions = res.data;
    });
    this.$http.get("/api/v1/Incomes").then((res) => {
      this.personalIncomes = res.data;
    });
    this.$http.get("/api/v1/MakesOfCar").then((res) => {
      this.brandIDOfCars = res.data;
    });
    //患病
    this.$http.get("api/v1/Diseases").then((res) => {
      // this.brandIDOfCars = res.data;
      this.diseasesList1 = JSON.parse(JSON.stringify(res.data));
      this.diseasesList2 = JSON.parse(JSON.stringify(res.data));
      this.changeGender();
    });

    this.$http.get("/api/v1/MaritalStatus").then((res) => {
      this.maritalStatuses = res.data;
      this.$http.get(`/api/v1/Users/${this.account.id}`).then((res) => {
        this.provinceChange(res.data.provinceID);
        this.carMakeChange(res.data.brandIDOfCar);
        console.log("res", res);
        this.ruleForm = {
          ...this.ruleForm,
          ...res.data,
          purchasedYearOfCar: res.data.purchasedYearOfCar
            ? res.data.purchasedYearOfCar + ""
            : null,
          birthYearOfYoungChild: res.data.birthYearOfYoungChild
            ? res.data.birthYearOfYoungChild + ""
            : null,
        };
        console.log("this.ruleForm", this.ruleForm);
      });
    });
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    provinceChange(p) {
      this.ruleForm.cityID = "";
      this.$http.get(`/api/v1/Provinces/${p}/Cities`).then((res) => {
        this.cities = res.data;
      });
    },
    carMakeChange(p) {
      this.ruleForm.modelIDOfCar = "";
      this.$http.get(`/api/v1/MakesOfCar/${p}/ModelsOfCar`).then((res) => {
        this.modelIDOfCars = res.data;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const payload = { ...this.ruleForm };
          if (!this.ruleForm.brandIDOfCar || this.ruleForm.brandIDOfCar === 1) {
            delete payload.modelIDOfCar;
            delete payload.purchasedYearOfCar;
          }
          if (!this.ruleForm.parnetsOfChilden) {
            delete payload.birthYearOfYoungChild;
          }

          if (payload.familyDisease.length !== 0) {
            payload.familyDisease = payload.familyDisease.join(",");
          }
          if (payload.personalDisease.length !== 0) {
            payload.personalDisease = payload.personalDisease.join(",");
          }
          console.log("payload", payload);

          const res = await this.$http.post(
            `/api/v1/Accounts/${this.account.id}/Profile`,
            null,
            {
              params: payload,
            }
          );
          if (res.status === 200) {
            this.$message.success(this.$t("save_success"));
            this.$http.get("api/v1/Users/" + this.account.id).then((res) => {
              if (res.status === 200) {
                this.setUserInfo(res.data);
              }
            });
          } else {
            this.$message.error(res.statusText);
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //改变性别
    changeGender() {
      console.log("改变性别", this.ruleForm.gender);
      this.diseasesList1 = JSON.parse(JSON.stringify(this.diseasesList2));
      if (this.ruleForm.gender === 1) {
        const index1 = this.diseasesList1.findIndex((item) => {
          return item.id === 28;
        });
        console.log(index1);
        this.diseasesList1.splice(index1, 1);
      }

      if (this.ruleForm.gender === 2) {
        const index2 = this.diseasesList1.findIndex((item) => {
          return item.id === 18;
        });
        this.diseasesList1.splice(index2, 1);
        const index3 = this.diseasesList1.findIndex((item) => {
          return item.id === 21;
        });
        this.diseasesList1.splice(index3, 1);
      }
    },
    changePersonalDisease() {
      const data = this.ruleForm.personalDisease;
      if (data.length === 2 && data[0] === 34) {
        this.ruleForm.personalDisease.shift();
      } else if (data[data.length - 1] === 34) {
        this.ruleForm.personalDisease = [34];
      }
      console.log("111", this.ruleForm.personalDisease);
    },
    changeFamilyDisease() {
      const data = this.ruleForm.familyDisease;
      if (data.length === 2 && data[0] === 34) {
        this.ruleForm.familyDisease.shift();
      } else if (data[data.length - 1] === 34) {
        this.ruleForm.familyDisease = [34];
      }
    },
  },
  computed: {
    ...mapGetters(["account", "userInfo"]),
  },
};
</script>
