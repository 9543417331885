<template>
  <div class="account-form">
    <div class="account-card">
      <div class="account-card__label">{{ $t("avatar") }}</div>
      <div class="account-card__content">
        <div class="user-logo">
          <img :src="account.avatarUrl" :alt="$t('avatar')" />
        </div>
      </div>
      <div class="account-card__action">
        <my-upload
          field="source"
          @crop-upload-success="cropUploadSuccess"
          v-model="show"
          :width="300"
          :height="300"
          :noSquare="true"
          url="/api/v1/AvatarUrl"
          :withCredentials="true"
          img-format="png"
        ></my-upload>
        <el-link @click="toggleShow">{{ $t("edit") }}</el-link>
      </div>
    </div>
    <div class="account-card">
      <div class="account-card__label" style="    margin-top: -47px;">
        {{ $t("mobile") }}
      </div>
      <div class="account-card__content">
        <div>{{ this.account.mobile || $t("not_fill") }}</div>
        <div class="text-black-light mt-10 text-min">
          {{ $t("account_tip1") }}
        </div>
        <div class="text-black-light text-min">
          {{ $t("account_tip2") }}
          <el-tooltip placement="top">
            <div slot="content">
              <div class="text-black-light text-min">
                {{ $t("attentions") }}
              </div>
              <div class="text-black-light text-min">
                {{ $t("account_tip3") }}
              </div>
              <div class="text-black-light text-min">
                {{ $t("account_tip4") }}
              </div>
              <div class="text-black-light text-min">
                {{ $t("account_tip5") }}
              </div>
              <div class="text-black-light text-min">
                {{ $t("account_tip6") }}
              </div>
              <div class="text-black-light text-min">
                {{ $t("account_tip7") }}
              </div>
            </div>
            <i class="el-icon-aliiconfontquestion"></i>
          </el-tooltip>
        </div>
      </div>
      <div
        class="account-card__action"
        @click="setDialogVisible({ editMobile: true })"
        v-if="!account.activeMobile"
      >
        <el-link>{{ $t("edit") }}</el-link>
      </div>
    </div>
    <div class="account-card">
      <div class="account-card__label">{{ $t("password") }}</div>
      <div class="account-card__content">
        <div>******</div>
        <div class="text-black-light mt-10 text-min">
          {{ $t("edit_password_tip") }}
        </div>
      </div>
      <div class="account-card__action">
        <el-link @click="setDialogVisible({ editPwd: true })">{{
          $t("edit")
        }}</el-link>
      </div>
    </div>
    <div class="account-card">
      <div class="account-card__label" style="margin-top:-50px;">
        {{ $t("zfb_account") }}
      </div>
      <div class="account-card__content">
        <div>{{ userInfo.name || "-" }}</div>
        <div>{{ account.alipay || "-" }}</div>
        <div class="text-black-light mt-10 text-min">
          {{ $t("edit_zfb_account_tip") }}
        </div>
      </div>
      <div
        class="account-card__action"
        @click="setDialogVisible({ editAlipay: true })"
      >
        <el-link v-if="!account.alipyConfirmed">{{ $t("edit") }}</el-link>
      </div>
    </div>

    <el-dialog
      class="edit-pwd-dialog"
      width="465px"
      append-to-body
      :title="$t('edit_password')"
      @close="setDialogVisible({ editPwd: false })"
      :visible="!!dialogVisible && !!dialogVisible.editPwd"
    >
      <edit-pwd-form :isForgotMode="true"></edit-pwd-form>
    </el-dialog>

    <el-dialog
      class="edit-alipay-dialog"
      width="465px"
      append-to-body
      :title="$t('edit_alipay')"
      @close="setDialogVisible({ editAlipay: false })"
      :visible="!!dialogVisible && !!dialogVisible.editAlipay"
    >
      <edit-alipay-form></edit-alipay-form>
    </el-dialog>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import { mapGetters, mapActions } from "vuex";
import EditPwdForm from "components/edit-password-form";
import EditAlipayForm from "components/edit-alipay-form";
import myUpload from "vue-image-crop-upload";

export default {
  data() {
    return {
      show: false,
      params: {},
      headers: {}
    };
  },
  methods: {
    ...mapActions(["setDialogVisible", "setAccount"]),
    toggleShow() {
      this.show = !this.show;
    },
    cropUploadSuccess(res) {
      if (res.status === 200 || res.code === 200) {
        this.$http
          .post(`/api/v1/Accounts/${this.account.id}/Avatar`, null, {
            params: {
              id: this.account.id,
              avatarUrl: res.data.avatarUrl
            }
          })
          .then(nRes => {
            if (nRes.status === 200) {
              this.toggleShow();
              this.$message.success(this.$t("edit_success"));
              this.$http.get("/api/v1/Accounts/" + this.account.id).then(nr => {
                if (nr.status === 200 && nr.data.id) {
                  this.setAccount(nr.data);
                }
              });
            } else {
              this.$message.error(this.$t("edit_failed"));
            }
          });
      }
    }
  },
  computed: {
    ...mapGetters(["account", "userInfo", "dialogVisible"])
  },
  components: {
    EditPwdForm,
    EditAlipayForm,
    myUpload
  }
};
</script>
