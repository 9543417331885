<template>
  <div class="center-setting">
    <el-menu
      :default-active="activeIndex"
      class="setting-menu"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="account">{{ $t("account_info") }}</el-menu-item>
      <el-menu-item index="user">{{ $t("personal_info") }}</el-menu-item>
      <el-menu-item index="third">{{ $t("third_binding") }}</el-menu-item>
    </el-menu>
    <div class="card">
      <PersonForm v-show="activeIndex === 'user'" />
      <AccountForm v-show="activeIndex === 'account'" />
      <ThirdPartBinding v-show="activeIndex === 'third'" />
    </div>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import PersonForm from "./person-form";
import AccountForm from "./account-form";
import ThirdPartBinding from "./thirdpart-binding";
export default {
  data() {
    return {
      activeIndex: "account"
    };
  },
  methods: {
    handleSelect(v) {
      this.activeIndex = v;
    }
  },
  created() {
    if (this.$route.query.tab) {
      this.activeIndex = this.$route.query.tab;
    }
  },
  components: {
    PersonForm,
    AccountForm,
    ThirdPartBinding
  }
};
</script>
