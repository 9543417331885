<template>
  <!-- registry -->
  <div class="registry-form">
    <el-alert
      v-if="!registryForm.valid"
      class="registry-error"
      :title="registryForm.tip"
      type="error"
      show-icon
    >
    </el-alert>

    <el-form
      ref="registryForm"
      :model="registryForm"
      @keyup.enter.native="registry()"
    >
      <el-form-item>
        <el-input
          v-model="registryForm.originPassword"
          :placeholder="$t('origin_password')"
          show-password
          auto-complete="new-password"
          @focus="formFocus()"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="registryForm.password"
          :placeholder="$t('new_password')"
          show-password
          auto-complete="new-password"
          @focus="formFocus()"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="registryForm.confirmpassword"
          :placeholder="$t('confirm_password')"
          show-password
          auto-complete="new-password"
          @focus="formFocus()"
        ></el-input>
      </el-form-item>
      <el-button
        :loading="loading"
        class="registry-btn"
        type="primary"
        round
        @click="registry()"
      >
        {{ $t("confirm") }}
      </el-button>
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      committing: false,
      errMsg: " ",
      registryForm: {
        originPassword: "",
        password: "",
        confirmpassword: "",
        valid: true,
        tip: ""
      }
    };
  },
  computed: {
    ...mapGetters(["account"])
  },
  methods: {
    ...mapActions(["setDialogVisible"]),
    validateRegistryForm() {
      if (!this.registryForm.originPassword) {
        return this.registryValidateError(this.$t("edit_password_tip1"));
      }
      if (!/^[0-9A-Za-z]{6,64}$/.test(this.registryForm.originPassword)) {
        return this.registryValidateError(this.$t("edit_password_tip2"));
      }
      if (!this.registryForm.password) {
        return this.registryValidateError(this.$t("edit_password_tip3"));
      }
      if (!/^[0-9A-Za-z]{6,64}$/.test(this.registryForm.password)) {
        return this.registryValidateError(this.$t("edit_password_tip2"));
      }
      if (this.registryForm.password !== this.registryForm.confirmpassword) {
        return this.registryValidateError(this.$t("registry_field_tip5"));
      }
    },
    formFocus() {
      this.registryForm.valid = true;
    },
    async registry() {
      this.validateRegistryForm();
      if (this.registryForm.valid) {
        this.registryLoading = true;

        const res = await this.$http.post(
          `/api/v1/Accounts/${this.account.id}/Password`,
          null,
          {
            params: {
              oldPassword: this.registryForm.originPassword,
              newPassword: this.registryForm.password,
              confirmedPassword: this.registryForm.confirmpassword
            }
          }
        );
        if (res.status) {
          this.$message.success(this.$t("edit_success"));
          this.setDialogVisible({ editPwd: false });
        } else {
          this.$message.error(res.statusText);
        }
      }
    },
    registryValidateError(tip) {
      this.registryForm.valid = false;
      this.registryForm.tip = tip;
      return;
    }
  }
};
</script>
<style src="./index.scss" lang="scss" scoped />
