<template>
  <!-- registry -->
  <div class="alipay-form">
    <el-form
      :model="form"
      :rules="rules"
      ref="editAlipayForm"
      label-width="100px"
    >
      <el-form-item :label="$t('real_name')" prop="realName">
        <el-input v-model="form.realName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('zfb_account')" prop="alipay">
        <el-input v-model="form.alipay"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit()">{{ $t("save") }}</el-button>
        <el-button @click="setDialogVisible({ editAlipay: false })">{{
          $t("cancel")
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      committing: false,
      form: {
        realName: "",
        alipay: ""
      },
      rules: {
        realName: [
          {
            required: true,
            message: this.$t("input_real_name"),
            trigger: "blur"
          }
        ],
        alipay: [
          {
            required: true,
            message: this.$t("input_alipay_account"),
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["account"])
  },
  methods: {
    ...mapActions(["setDialogVisible"]),
    // formFocus() {
    //   this.form.valid = true;
    // },
    async submit() {
      this.$refs["editAlipayForm"].validate(async valid => {
        if (valid) {
          const res = await this.$http.post(
            `/api/v1/Accounts/${this.account.id}/Alipay`,
            null,
            {
              params: {
                id: this.account.id,
                ...this.form
              }
            }
          );
          if (res.status === 200) {
            this.$message.success(this.$t("save_success"));
            this.setDialogVisible({ editAlipay: false });
            this.refreshAccount();
          } else {
            this.$message.error(res.statusText);
          }
        }
      });
    },
    refreshAccount() {
      this.$http.get(`/api/v1/Accounts/${this.account.id}`).then(res => {
        if (res.status === 200) {
          this.setAccount(res.data);
        }
      });
    }
  }
};
</script>
<style src="./index.scss" lang="scss" scoped />
